import { IEnvVariables } from "@dcl-ue/shared-components/src/types/AppEnvVariables.types";
import { IRecaptchaEnvVars } from "@dcl-ue/shared-components/src/types/RecaptchaEnvVar.types";

import { defaultEnvVars } from "@dcl-ue/shared-components/src/Constants/constants";

export interface ITransactEnvVariables extends IEnvVariables {
  DCPCommonLeadService: string;
  dcpUnifiedManipulationAPI: string;
  ciamClientId: string;
  tokenURL: string;
  authorizeURL: string;
  refreshTokenURL: string;
  practicalLawLeadCaptureUrl: string;
  defaultPlanDuration: string;
  site?: string;
  defaultNumberOfAttorneys: string;
  fetchTaxEndpoint: string;
  isDeferredLogin: string;
  organizationAppType: string;
  fetchOrganizationTypeEndpoint: string;
  disableRegexPattern: string;
  allowedRegexExpression: string;
  boomiPaymetricDecryptEndpoint: string;
  orgTypeDetailsEndpoint: string;
  highqSuccessPage: string;
  configurationName: string;
  applicationId: string;
  companyId: string;
  currency: string;
  clearCartIdDuration: string;
  enEmptyCartRedirectionURL: string;
  frEmptyCartRedirectionURL: string;
  simulateOrderExtensibleAttributes: string;
  datadogClientToken: string;
  datadogSite: string;
  datadogApplicationId: string;
  datadogService: string;
  datadogEnv: string;
  encryptUserDataEndpoint: string;
  productPricingServiceEndpoint: string;
  dcpShareCartEndPoint: string;
  productPricingServiceAppId: string;
  createProposalEndpointV2: string;
  cartManipulationEndpointV2: string;
  cartLineItemDetailsV2: string;
  applyPromotionServiceV2: string;
  useV2TurboPricing: "yes" | "no";
  fingerPrintScriptSource: string;
  orgId: string;
  merchantId: string;
  storeId: string;
  inventoryAPITriggerTime: string;
  apigeeInventoryAPIEndPoint: string;
  apigeeProductRecommendationAPIEndPoint: string;
  enCAProductPageURL: string;
  frCAProductPageURL: string;
  isOnePassEnabled: string;
  dcpCountryListEndpoint: string;
  originSite: string;
  smartSaverPurchaseOption?: string;
  oneTimeUpdatePurchaseOption?: string;
}

export const defaultEnvVariables = {
  ...defaultEnvVars,
  DCPCommonLeadService: "",
  ciamClientId: "",
  tokenURL: "",
  authorizeURL: "",
  refreshTokenURL: "",
  reCaptchaSiteKey: "",
  practicalLawLeadCaptureUrl: "",
  defaultPlanDuration: "1",
  defaultNumberOfAttorneys: "1",
  fetchTaxEndpoint: "",
  isDeferredLogin: "",
  organizationAppType: "",
  fetchOrganizationTypeEndpoint: "",
  disableRegexPattern: "",
  allowedRegexExpression: "",
  boomiPaymetricDecryptEndpoint: "",
  orgTypeDetailsEndpoint: "",
  highqSuccessPage: "",
  configurationName: "",
  applicationId: "",
  companyId: "",
  currency: "",
  clearCartIdDuration: "",
  enEmptyCartRedirectionURL: "",
  frEmptyCartRedirectionURL: "",
  simulateOrderExtensibleAttributes: "",
  datadogClientToken: "",
  datadogSite: "",
  datadogApplicationId: "",
  datadogService: "",
  datadogEnv: "",
  encryptUserDataEndpoint: "",
  fingerPrintScriptSource: "",
  orgId: "",
  useV2TurboPricing: "no",
  merchantId: "",
  storeId: "",
  inventoryAPITriggerTime: "20:00:00",
  apigeeInventoryAPIEndPoint: "",
  apigeeProductRecommendationAPIEndPoint: "",
  enCAProductPageURL: "",
  frCAProductPageURL: "",
  isOnePassEnabled: "true",
  dcpCountryListEndpoint: "",
} as ITransactEnvVariables;

let envVariables: ITransactEnvVariables;

export const initEnvVariables = () => {
  const envConfigFromAEM = document.getElementById("env-config") as HTMLElement;
  const envConfigParsed = JSON.parse(
    envConfigFromAEM?.innerHTML ?? JSON.stringify(defaultEnvVariables)
  );
  envVariables = Object.assign({}, defaultEnvVariables, envConfigParsed);
};

export const getEnvVariables = () => {
  if (!envVariables) {
    initEnvVariables();
  }

  return envVariables;
};

export const getEnvVar = (key: keyof ITransactEnvVariables): string => {
  const envVars = getEnvVariables();
  return envVars[key] || "";
};

export const getRecaptchaEnvVar = (): IRecaptchaEnvVars => {
  const {
    apigeeClientId,
    reCaptchaVerifyUrl,
    reCaptchaSiteKey,
  } = getEnvVariables();
  return {
    apiKey: apigeeClientId,
    reCaptchaVerifyUrl,
    reCaptchaSiteKey,
  };
};
