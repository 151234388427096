import { getEnvVar } from "../../utilities/appEnvVariables";
import { getAppLogger } from "./AppLogger";
import { ICustomError } from "./types";

export const getDataDogConfig = () => {
  const apigeeURL = getEnvVar("apigeeUrl");
  const datadogConfig = {
    applicationId: getEnvVar("datadogApplicationId"),
    clientToken: getEnvVar("datadogClientToken"),
    site: getEnvVar("datadogSite"),
    service: getEnvVar("datadogService"),
    env: getEnvVar("datadogEnv"),
    allowedTracingUrls: [(url) => url?.startsWith(apigeeURL)],
  };

  return datadogConfig;
};

export const logApiErrorMessage = (errorData: ICustomError) => {
  const { error, errorCode, optionalParams } = errorData;
  const siteId = getEnvVar("site");
  const logType = "Error";

  const customMessage = [
    siteId ?? "Unknown Site",
    logType,
    errorCode ?? "Unknown error",
    error?.message ?? "Unknown error occurred",
  ]
    .filter((value) => value)
    .join(" | ");

  const customError = new Error();
  Object.assign(customError, error);
  customError.message = customMessage;
  getAppLogger().error(customError, optionalParams);
};
